<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-button type="success" icon="el-icon-plus" @click="onAdd">
          添加
        </el-button>
        <el-button type="danger" icon="el-icon-delete" @click="delAll">
          批量删除
        </el-button>
        <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      </div>
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="depid">
          <el-cascader
            v-model="query.depids"
            :options="departmentList"
            clearable
            :show-all-levels="false"
            :props="{
              value: 'id',
              label: 'name',
              children: 'children',
              multiple: true,
              emitPath: false
            }"
            placeholder="请选择所属部门"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            v-model="query.name"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone_username">
          <el-input
            v-model="query.phone_username"
            placeholder="请输入用户名、联系方式"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="cardno">
          <el-input
            v-model="query.cardno"
            placeholder="请输入身份证号、警号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>

      <!--数据表格-->
      <el-table
        v-loading="listLoading"
        :data="list"
        element-loading-text="正在加载 ..."
        border
        fit
        highlight-current-row
        @selection-change="handleSelectionChange"
        @row-dblclick="onEdit"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="姓名" prop="name" />
        <el-table-column label="用户名（账号）" prop="username" />
        <el-table-column label="身份证号码" prop="idcard" width="160" />
        <el-table-column label="警号" prop="policeCard" width="160" />
        <el-table-column label="联系方式" prop="telphone" />
        <el-table-column label="性别" prop="sex" />
        <el-table-column label="所属部门" prop="department" width="160" />
        <el-table-column label="服务网点" width="180">
          <template slot-scope="scope">
            <span
              v-if="scope.row.addressId != '' && scope.row.addressId != null"
              >{{ scope.row.wdName + '（' + scope.row.wdAddress + '）' }}
            </span>
            <span v-else>未分配</span>
          </template>
        </el-table-column>
        <el-table-column
          label="注册时间"
          prop="createDate"
          width="150"
          sortable
        />
        <el-table-column label="是否激活" prop="isActive">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isActive"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="changeSwitch($event, scope.row, scope.$index)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="onEdit(scope.row)">
              编辑
            </el-button>
            <el-button type="danger" size="small" @click="onDel(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageInfo.startPage"
          :page-sizes="[10, 20, 30, 50, 100, 200]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
          @size-change="onSizeChange"
          @current-change="onPageChange"
        />
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑信息"
      width="800px"
      :visible.sync="selectDlgShow"
      :close-on-click-modal="false"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form
          ref="ruleForm"
          :rules="rules"
          :model="form"
          label-width="120px"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              v-model="form.username"
              placeholder="请输入用户名"
              clearable
            />
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="form.password"
              placeholder="请输入密码"
              show-password
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="再次确定密码" prop="passwordTwo">
            <el-input
              v-model="form.passwordTwo"
              placeholder="请再次输入密码"
              show-password
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="所属部门" prop="departId">
            <div class="block">
              <el-cascader
                v-model="form.departId"
                class="select-input"
                :options="departmentList"
                clearable
                :show-all-levels="false"
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'children',
                  emitPath: false,
                  checkStrictly: true 
                 
                }"
                placeholder="请选择所属部门"
              >
              </el-cascader>
            </div>
          </el-form-item>
          <el-form-item label="角色权限" prop="roleId">
            <el-select
              v-model="form.roleId"
              placeholder="请选择用户角色权限"
              class="select-input"
              clearable
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="真实姓名" prop="name">
            <el-input
              v-model="form.name"
              type="text"
              placeholder="请输入真实姓名"
              clearable
            />
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model="form.sex" placeholder="请选择性别" clearable>
              <el-option value="男">男</el-option>
              <el-option value="女">女</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号码" prop="telephone">
            <el-input
              v-model="form.telephone"
              type="text"
              placeholder="请输入手机号码"
              clearable
            />
          </el-form-item>
          <el-form-item label="身份证号码" prop="idCard">
            <el-input
              v-model="form.idCard"
              type="text"
              placeholder="请输入身份证"
              clearable
            />
          </el-form-item>
          <el-form-item label="警号" prop="policeCard">
            <el-input
              v-model="form.policeCard"
              type="text"
              placeholder="请输入警员编号"
              clearable
            />
          </el-form-item>
          <el-form-item label="服务网点" prop="addressId">
            <el-select
              v-model="form.addressId"
              filterable
              placeholder="请选择服务网点"
              class="select-input"
              clearable
            >
              <el-option
                v-for="item in wdList"
                :key="item.id"
                :label="item.address"
                :value="item.id"
              >
                <span style="float: left">{{ item.address }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.name
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" plain @click="selectDlgShow = false">
          关 闭
        </el-button>
        <el-button size="small" @click="resetForm('ruleForm')">重 置</el-button>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">
          保 存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveUser,
  getUserList,
  delUser,
  updateIsActive,
  updatePassword
} from '@/api/admin/user';
import { getDepartmentList } from '@/api/admin/department';
import { getRoleList } from '@/api/admin/role';
import { getList as getAddressList } from '@/api/admin/address';
import { Message, MessageBox } from 'element-ui';
import { constants } from 'fs';
import Validators from '@/components/common/validata.js';

export default {
  name: 'User',
  data() {
    return {
      query: {
        depid: '',
        depids: [],
        name: '',
        phone_username: '',
        cardno: ''
      },
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        depid: '',
        depids: [],
        name: '',
        phone_username: '',
        cardno: ''
      },
      list: [],
      departmentList: [],
      roleList: [],
      wdList: [],
      form: {
        id: '',
        name: '',
        sex: '',
        telephone: '',
        idCard: '',
        departId: '',
        password: '',
        passwordTwo: '',
        username: '',
        roleId: '',
        policeCard: '',
        addressId: 0
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度至少6位或以上', trigger: 'blur' }
        ],
        passwordTwo: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度至少6位或以上', trigger: 'blur' }
        ],
        departId: [
          { required: true, message: '请选择所属部门', trigger: 'blur' }
        ],
        roleId: [
          { required: true, message: '请选择用户角色', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        telephone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: Validators.checkPhone, trigger: 'blur' }
        ]
      },
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: []
    };
  },
  created() {
    //初始化数据
    this.fetchData();
    this.loadDeparmentList();
    this.loadRoleList();
    this.loadWdAddressList();
  },
  methods: {
    loadDeparmentList() {
      //获取部门列表
      this.departmentList = [];
      getDepartmentList().then(response => {
        this.departmentList = response.data;
      });
    },
    loadRoleList() {
      //加载角色列表
      this.roleList = [];
      getRoleList({ startPage: 1, pageSize: 100 }).then(response => {
        this.roleList = response.data.records;
      });
    },
    loadWdAddressList() {
      //加载预约服务网点
      this.wdList = [];
      getAddressList({ startPage: 1, pageSize: 100 }).then(resp => {
        if (resp.code === 200) {
          this.wdList = resp.data.records;
        }
      });
    },
    changeSwitch(data, b, index) {
      //开关切换
      var userid = b.userid;
      var isActive = b.isActive;
      updateIsActive(userid, isActive).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: resp.msg,
            type: 'success'
          });
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.pageInfo.depid = this.query.depid;
      this.pageInfo.depids = this.query.depids;
      this.pageInfo.name = this.query.name;
      this.pageInfo.phone_username = this.query.phone_username;
      this.pageInfo.cardno = this.query.cardno;
      this.fetchData();
    },
    onRefresh() {
      this.loadDeparmentList();
      this.loadRoleList();
      this.fetchData();
    },
    onAdd() {
      this.form = {
        id: '',
        name: '',
        sex: '',
        telephone: '',
        idCard: '',
        departId: '',
        password: '',
        passwordTwo: '',
        username: '',
        roleId: '',
        policeCard: '',
        addressId: ''
      };
      this.selectDlgShow = true;
    },
    onEdit(row) {
      this.form = {
        id: row.userid,
        name: row.name,
        sex: row.sex,
        telephone: row.telphone,
        idCard: row.idcard,
        departId: row.departId,
        password: row.password,
        passwordTwo: row.password,
        username: row.username,
        roleId: row.roleId,
        policeCard: row.policeCard,
        addressId: row.addressId
      };
      this.selectDlgShow = true;
    },
    delAll() {
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.userid);
        });
        delUser({ ids: ids }).then(resp => {
          this.$message({
            type: 'success',
            message: resp.msg
          });
          this.fetchData();
        });
      });
    },
    onDel(row) {
      this.$confirm('您确定要删除此用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUser({ id: row.userid }).then(resp => {
          this.$message({
            type: 'success',
            message: resp.msg
          });
          this.fetchData();
        });
      });
    },
    fetchData() {
      this.listLoading = true;
      getUserList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          var pwd1 = this.form.password;
          var pwd2 = this.form.passwordTwo;
          if (pwd1 != pwd2) {
            this.$message({
              message: '两次密码输入不一致，请重新输入',
              type: 'warning'
            });
            return false;
          }
          this.save();
        } else {
          return false;
        }
      });
    },
    save() {

 
      this.dlgLoading = true;
      saveUser(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error(resp.msg);
            this.dlgLoading = false;
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.upload-file {
  display: inline-block;
  margin-right: 10px;
}
.select-input {
  width: 350px !important;
}
</style>
